<template>
    <div :class="!hasProfile ? 'wrap p-child-add' : 'wrap p-child-choice'">

        <template v-if="hasProfile === undefined">
        </template>
        <template v-else-if="!hasProfile">
            <layout-header title="자녀등록"></layout-header>

            <div class="container">
                <div class="content" style="display: block;">
                    <div class="img-wrap">
                        <img alt="" src="/assets/images/member/img_child_add.png">
                    </div>
                    <div class="guide-text" >
                        <strong>{{ coAccountVO.coEmailId }}</strong>
                        <span>님 자녀를 추가해주세요.</span>
                    </div>
                    <div class="button-area" style="margin-top: 16px;">
                        <button class="btn-member purple lg" type="button">
                            <router-link :to="{name: 'profileJoin'}">자녀 추가</router-link>
                        </button>
                        <button class="btn-member lg" type="button" @click="doLogout">로그아웃
                        </button>
                        <button class="btn-member blue lg" type="button">
                            <router-link :to="{name: 'accountMyPage'}" target="_blank">개인 정보 수정</router-link>
                        </button>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="hasProfile">
            <layout-header title="" z-index="-1"></layout-header>

            <div class="container" style="overflow: hidden">
                <div class="content" style="margin-top: 0">
                    <div class="guide-text">학습 할 자녀를 선택하세요</div>
                    <div>
                        <ul class="child-list" style="overflow-y: auto; height:200px;">
                            <li v-for="(item, index) in profileAccountList" v-bind:key="index"
                                :value="item.coaccountVO.caidx"
                                style="margin: 16px auto;"
                                @click="goEdu(index)">
                                <div class="img-box"><img :src="item.coaccountVO.profileSrc" alt=""></div>
                                <div class="info-box" style="margin-left: 54px">
                                    <span><strong>{{ item.coaccountVO.nickName }}</strong> 어린이</span>
                                    <span class="slash">/</span>
                                    <span><strong>{{ item.coinfoVO.coName }}</strong></span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <button class="btn-member purple lg" type="button" style="margin-top: 16px;">
                            <router-link :to="{name: 'profileJoin'}">자녀 추가</router-link>
                        </button>
                        <button class="btn-member lg" type="button" @click="doLogout">로그아웃
                        </button>
                        <button class="btn-member blue lg" type="button">
                            <a @click="pageOpen('accountMyPage')" href="javascript:;">개인 정보 수정</a>
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader";
import commonUtils from "@/commons/utils/common.utils";

export default {
    name: "profileSelect",
    components: {LayoutHeader},
    data() {
        const coEmailId = this.getLoginMainId()

        return {

            coEmailId,
            hasProfile: undefined,
            coAccountVO: {},
            profileAccountList: Object

        }
    },
    created() {
        this.getAccountInfo()
    },
    mounted() {
        this.doLogoutProfile()
        this.requireLoginMainWithoutAlert()
    },
    methods: {

        doLogout() {

            this.$eventBus.$emit("startProgress", 2)
            this.$nextTick(() => {
                this.$session.destroy()
                this.$eventBus.$emit("doneProgress", 2)

                location.reload()
            })

        },

        getAccountInfo() {

            const self = this

            this.axiosCaller.get(self, self.APIs.CO_ACCOUNT + '/', {
                coCode: self.webUtils.getSubdomain(),
                coEmailId: self.getLoginMainId()
            }, (res) => {

                const result = res.data
                self.hasProfile = result.hasProfile
                self.coAccountVO = result.coAccountVO

                if (self.hasProfile) {
                    self.getProfile(res.data.coAccountVO)
                }

            })

        },

        getProfile() {

            this.$eventBus.$emit("startProgress")

            const self = this
            const _ = window._

            this.axiosCaller.get(self, self.APIs.CO_PROFILE + '/getChild', {
                coEmailId: self.coEmailId
            }, (res) => {

                _.forEach(res.data.profileAccountList, v => {

                    if (commonUtils.nullCheck(v.coinfoVO)) {
                        v.coinfoVO = {}
                        v.coinfoVO.coName = "반 배정 없음"
                    }

                })

                self.profileAccountList = res.data.profileAccountList
                self.$eventBus.$emit("doneProgress", 500)

            })
        },

        goEdu(e) {

            const self = this
            let isProfileAuth

            let caidx = self.profileAccountList[e].coaccountVO.caidx
            let coEmailId = self.profileAccountList[e].coaccountVO.coEmailId

            this.axiosCaller.get(self, self.APIs.CO_ACCOUNT + '/', {
                caidx: caidx
            }, (res) => {

                const result = res.data
                isProfileAuth = result.coAccountVO.authEmail
                
                if (!isProfileAuth) {
                    self.$router.push({
                        name: "profileAuth",
                        query: {
                            caidx: caidx
                        },
                    })
                } else {

                    this.doLoginProc(coEmailId, () => {

                        this.setSessionKeep(this.isKeepSession);

                        self.$router.push({
                            name: "courseList",
                        })

                        return
                    });

                }

            })

        },

        pageOpen(link, target, isCloseBTN) {

            const url = this.APIs.BASE_URL + "/" + link

            const temp = {
                url: url,
                isCloseBTN: "Y",
            }
            this.appOpen(temp);
        }

    },

}
</script>

<style scoped>

</style>